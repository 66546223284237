import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AppConst} from "src/app/App.Const";
import {SupabaseService} from "../../../shared/services/supabase.service";
import {UserService} from "../../../shared/services/user.service";

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loadinBtn = false
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private readonly supabase: SupabaseService,
    private  userService: UserService,
    private fb: FormBuilder, public router: Router) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });
  }

  ngOnInit() {
   // this.initSupabase();
  }

async  initSession(): Promise<void> {
    await  this.initSupabase();
  //this.router.navigate([AppConst.MAIN_ROUTES.EMAIL_VERIFICATION])
   // this.router.navigate(['auth/login']);
  console.log("SAISSION INITIALIZED");
}
  async initSupabase() {
    this.supabase.client.auth.getUser().then(({ data, error }) => {
      console.log("FRESH DATA --->", data);
      this.userService.getUserInfoRaw().subscribe({
        next: response => {
          console.log("RAW DATA DONE", response);
         // this.router.navigate([AppConst.MAIN_ROUTES.EMAIL_VERIFICATION])
        this.router.navigateByUrl(AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR);
        },
        error: error => {
          this.router.navigateByUrl(AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR);
          console.log("unable to get investor list", error);
        }
      });
      //console.log("user called");
     // this.router.navigateByUrl(AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR);
      // if (data.user) {
      //   this.router.navigate([AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR]);
      // } else
      // {
      //   console.log("UNABLE TO SET SUPABASE SESSION",error);
      // }
    })
  }

  async onSubmit(): Promise<void> {
    this.loadinBtn = true
    try {
      await this.supabase.signOut();
      const email = this.loginForm.value.email as string
      const password = this.loginForm.value.password as string
      const {data,error } = await this.supabase.signIn(email,password)
      // const { data, error } = await this.supabase.signInWithEmail();
     await this.userService.initToken(data);
      console.log("data from auth", data);
      console.log(error);
      if (error) throw error

      ///this.router.navigate([AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR]);
      await this.initSession();
      // this.router.navigate([AppConst.MAIN_ROUTES.EMAIL_VERIFICATION])
    } catch (error) {
      console.log("LOGIN ERROR", error);
      if (error instanceof Error) {
        return Swal.fire({
          icon: 'error',
          title: 'Authentication Error',
          text: error.message,
        });
      }
    } finally {
      this.loadinBtn = false
      //this.initSupabase();
      // this.loginForm.reset()
      //this.router.navigate([AppConst.MAIN_ROUTES.EMAIL_VERIFICATION])
   //   this.router.navigate([AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR]);
       //this.router.navigate([AppConst.MAIN_ROUTES.INVESTORS + "/" + AppConst.MAIN_ROUTES.INVESTOR]);

    }
  }
  // login() {
  //   if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
  //     let user = {
  //       email: "Test@gmail.com",
  //       password: "test123",
  //       name: "test user",
  //     };
  //     localStorage.setItem("user", JSON.stringify(user));
  //     this.router.navigate([AppConst.MAIN_ROUTES.INVESTOR + "/" + AppConst.MAIN_ROUTES.INVESTOR_LIST]);
  //   }
  // }

  showPassword() {
    this.show = !this.show
  }

}
