// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //// #### LOCALHOST DEBUG

  //supabaseUrl: 'https://rcxsqczjgozjwyfqtqem.supabase.co',
  //supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJjeHNxY3pqZ296and5ZnF0cWVtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzcwMTY1NjEsImV4cCI6MTk5MjU5MjU2MX0.XJ4CohyzuT_9XgO7m5J8Rr5b5Y722_u_mQ-_zTvklI4',
  // selectedWorkspace:'f6abad90-720c-4df9-bb9a-777052191bbd',
  //apiUrl: 'https://letztoken-backend.dev.ndbsoft.ovh/api',

  //// #### PROD DEBUG

  supabaseId:'mukabnvnoidrkemwqyom',
  supabaseUrl: 'https://mukabnvnoidrkemwqyom.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im11a2FibnZub2lkcmtlbXdxeW9tIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQ1MDI5OTcsImV4cCI6MjAwMDA3ODk5N30.DHMvTgNoAE42cMqL93ZKk2clIHWTrq0k9aFWur8ExFw',
 apiUrl: 'https://letztoken-backend.prod.ndbsoft.ovh/api',
 //apiUrl: 'http://localhost:8080/api',
  selectedWorkspace:'a49c9d2a-0878-4d9f-85c8-eaa82aaa88de'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
