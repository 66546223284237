import {Injectable} from '@angular/core';
import {BaseServiceService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseServiceService {

  private loggedUserRoleList = [];
  private loggedUserPermissionList: [];
  private  user:any = undefined;

  constructor() {
    super();
  }


  getUserInfo(): any {
    if (!this.user) {
      this.apiGet("/user/info").subscribe({
        next: response => {
          this.user = response;
          localStorage.setItem("userBackend", JSON.stringify(this.user));
          console.log("USER CREATED", localStorage);
          debugger
          return this.user;
        },
        error: error => {
          console.log("unable to get user  ", error);
          this.user =  null;
          localStorage.removeItem("userBackend");
          debugger
          return this.user;
        }
      });
    }
    else {
      return this.user;
    }
  }

  getUserInfoRaw(): any {
   return this.apiGet("/user/info");
  }
  getUserPermissionAndRole(): any {
    if (this.loggedUserRoleList.length <= 0) {
      this.apiGet("/user/info").subscribe({
        next: response => {
          this.loggedUserPermissionList = response.permissionList;
          this.loggedUserRoleList = response.roleList;
        },
        error: error => {
          console.log("unable to get user permissions list", error);
        }
      });
    }
    else {
      return this.loggedUserPermissionList;
    }

  }

  getUserList(): any {
    return this.apiGet("/user");
  }

  getUserDataById(params: any): any {
    return this.apiGet("/user/" + params);
  }

  checkUser(email: any): any {
    return this.apiGet("/user/" + email + "/check");
  }

  CreateUserData(params: any): any {
    return this.apiPost("/user", params);
  }

  unblockUser(params: any): any {
    return this.apiPost_WithoutBody("/user/" + params + '/unblock', params);
  }

  blockUser(params: any): any {
    return this.apiPost_WithoutBody("/user/" + params + '/block', params);
  }

  DeleteUser(params: any): any {
    return this.apiPost_WithoutBody("/user/" + params + '/delete', params);
  }

  UpdateUserData(params: any) {
    return this.apiPost("/user", params);
  }
  updatePassword(params: any) {
    return this.apiPost("/user", params);
  }

}
